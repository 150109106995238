<template>
  <v-container fluid>
    <v-main class="mx-auto">
      <!-- Tab -->
      <div class="d-flex justify-space-between">
        <h3 class="subheader__title mb-2">
          Horario semanal
        </h3>
      </div>
      <v-tabs v-model="tab" class="mb-5" color="secondary">
        <v-tab class="text-none">
          Horario semanal
        </v-tab>
        <v-tab v-if="myTeacherUser.role === 'admin'" class="text-none">
          Lista de clases
        </v-tab>
        <v-tab class="text-none">
          Lista de profesores
        </v-tab>
        <v-tab v-if="myTeacherUser.role === 'admin'" class="text-none">
          Lista de estudiantes
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <!-- Other header -->
          <div class="d-flex">
            <div class="d-flex flex-column">
              <classes-table />
              <template v-if="classSelected">
                <class-detail class="my-6" />
                <students-by-class-table />
                <new-student-modal v-if="myTeacherUser.role === 'admin'" v-model="showAddNewStudentModal"
                                   @close="closeNewStudentModal"
                                   @click:outside="setEditedStudent(null)"
                />
              </template>
            </div>
            <class-leyend class="ml-15" />
          </div>
          <div />
        </v-tab-item>
        <v-tab-item v-if="myTeacherUser.role === 'admin'">
          <div v-if="tab === 1" class="text-center">
            <classes-list-table />
            <form-button
              width="448px" height="50px" class="mt-15 mx-auto"
              :to="{name: 'AddClass'}"
            >
              Agregar clase
            </form-button>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div v-if="(tab === 2 && myTeacherUser.role === 'admin') || tab === 1 && myTeacherUser.role === 'teacher'" class="text-center">
            <teachers-table />
            <new-password-teacher-modal v-model="showNewTeacherPasswordModal" @close="closeNewTeacherPasswordModal"
                                        @click:outside="setEditedTeacher(null)"
            />
            <new-teacher-modal v-if="myTeacherUser.role === 'admin'" v-model="showAddNewTeacherModal"
                               @close="closeNewTeacherModal"
                               @click:outside="setEditedTeacher(null)"
            />
          </div>
        </v-tab-item>
        <v-tab-item v-if="myTeacherUser.role === 'admin'">
          <!-- this if destroys the component and mount it again -->
          <general-students-list v-if="tab === 3" />
        </v-tab-item>
      </v-tabs-items>
    </v-main>
  </v-container>
</template>

<script>
import classesTable from '@/components/schedule/ClassesTable'
import classLeyend from '@/components/schedule/ClassLegend'
import classDetail from '@/components/schedule/ClassDetail'
import studentsByClassTable from '@/components/schedule/StudentsByClassTable'
import { mapActions, mapState } from 'vuex'
import teachersTable from '@/components/schedule/TeachersTable'
import newTeacherModal from '@/components/modals/NewTeacherModal'
import newStudentModal from '@/components/modals/NewStudentModal'
import newPasswordTeacherModal from '@/components/modals/NewPasswordTeacherModal'
import GeneralStudentsList from '@/components/schedule/GeneralStudentsList'
import ClassesListTable from '@/components/schedule/ClassesListTable'
import formButton from '@/components/buttons/FormButton'

export default {
  name: 'Schedule',
  components: {
    ClassesListTable,
    GeneralStudentsList,
    classesTable,
    classLeyend,
    classDetail,
    studentsByClassTable,
    teachersTable,
    newTeacherModal,
    newStudentModal,
    newPasswordTeacherModal,
    formButton
  },
  data () {
    return {
      tab: null,
      showAddNewClassModal: false,
      showAddNewTeacherModal: false,
      showNewTeacherPasswordModal: false,
      showAddNewStudentModal: false
    }
  },
  computed: {
    ...mapState('auth', ['myTeacherUser']),
    ...mapState('schedule', ['editedTeacher', 'editedStudent', 'classSelected'])
  },
  watch: {
    editedTeacher (newValue) {
      if (newValue) {
        if (this.myTeacherUser.role === 'teacher') {
          this.showNewTeacherPasswordModal = true
        } else {
          this.showAddNewTeacherModal = true
        }
      }
    },
    editedStudent (newValue) {
      if (newValue) {
        this.showAddNewStudentModal = true
      }
    }
  },
  methods: {
    ...mapActions('schedule', ['setEditedTeacher', 'setEditedStudent']),
    closeNewTeacherModal () {
      this.showAddNewTeacherModal = false
      if (this.editedTeacher) {
        this.setEditedTeacher(null)
      }
    },
    closeNewTeacherPasswordModal () {
      this.showNewTeacherPasswordModal = false
      if (this.editedTeacher) {
        this.setEditedTeacher(null)
      }
    },
    closeNewStudentModal () {
      this.showAddNewStudentModal = false
      if (this.editedStudent) {
        this.setEditedStudent(null)
      }
    }
  }
}
</script>

<style scoped>

</style>
