<template>
  <div>
    <tslc-table :heads="tableHead" :items="allStudents" @sort="sort">
      <!--      <template v-slot:actions="{item}">-->
      <!--        <v-card class="tslc-table__action" :class="{'tslc-table__action&#45;&#45;active': item.active}">-->
      <!--          {{ item.active ? 'Activo' : 'Inactivo' }}-->
      <!--        </v-card>-->
      <!--      </template>-->
      <template v-slot:assistance="{item}">
        <div class="d-flex">
          <template v-if="item.active">
            <v-icon v-for="(assistance, index) in item.assistance" :key="index" class="mx-1" small :color="assistance.value? 'green': 'red'">
              {{ assistance.value ? 'mdi-circle' : 'mdi-close' }}
            </v-icon>
          </template>
          <template v-else>
            <v-icon v-for="i in 5" :key="i" class="mx-1" small color="red">
              mdi-close
            </v-icon>
          </template>
        </div>
      </template>
    </tslc-table>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <template v-slot:no-more>
        <div />
      </template>
      <template v-slot:no-results>
        <div />
      </template>
    </infinite-loading>
  </div>
</template>

<script>
import TslcTable from '@/components/TslcTable'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'GeneralStudentsList',
  components: {
    TslcTable
  },
  data () {
    return {
      tableHead: [
        {
          name: 'Nombre',
          value: 'firstName',
          sortBy: 'firstName'
        },
        {
          name: 'Apellido',
          value: 'lastName',
          sortBy: 'lastName'
        },
        {
          name: 'Correo electrónico',
          value: 'email',
          sortBy: 'email'
        },
        {
          name: 'Asistencia',
          value: 'assistance',
          sortBy: 'assistanceCounter'
        },
        {
          name: '',
          value: 'actions'
        }
      ],
      sortBy: 'createdAt',
      orientation: 'desc'
    }
  },
  computed: {
    ...mapState('schedule', ['allStudents', 'existNextStudents', 'classes', 'studentsByLesson'])
  },
  watch: {
    async classes () {
      await this.resetInfiniteLoading()
    },
    async studentsByLesson () {
      await this.resetInfiniteLoading()
    }
  },
  async destroyed () {
    await this.resetAllStudents()
  },
  methods: {
    ...mapActions('schedule', ['fetchAllStudents', 'resetAllStudents']),
    async infiniteHandler ($state) {
      await this.fetchAllStudents({ orderByField: this.sortBy, orientation: this.orientation })
      this.existNextStudents ? $state.loaded() : $state.complete()
    },
    async resetInfiniteLoading () {
      await this.$refs.infinite.stateChanger.reset()
      console.log(this.$refs.infinite)
      await this.resetAllStudents()
    },
    async sort ({ sortBy, orientation }) {
      this.sortBy = sortBy
      this.orientation = orientation
      await this.resetInfiniteLoading()
    }
  }
}
</script>

<style scoped lang="scss">

.tslc-table__action {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 98px;
  height: 40px;
  background: #929292;
  border-radius: 5px!important;

  &--active {
    background: #DCC02D;
  }
}

</style>
