<template>
  <div class="d-flex flex-column align-center">
    <v-simple-table style="width: 100%;" fixed-header class="students-table" max-height="70vh">
      <thead class="students-table__head">
        <tr>
          <th v-for="(th, i) in tableHeads" :key="i" class="students-table__head-item" v-text="th" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(studentKey, index) in Object.keys(classStudents)" :key="index">
          <td class="students-table__head-item" style="user-select: none;">
            {{ index + 1 }}.
          </td>
          <td class="students-table__item" style="user-select: none;">
            {{ classStudents[studentKey].firstName }} {{ classStudents[studentKey].lastName }}
          </td>
          <td class="students-table__item">
            <div class="d-flex justify-space-between align-center">
              {{ classStudents[studentKey].email }}
              <v-img v-if="classStudents[studentKey].guest" src="/img/invited-user.svg"
                     style="max-width: 23px; max-height: 23px;"
              />
            </div>
          </td>
          <td class="d-flex justify-center align-center" style="user-select: none;">
            <template v-if="myTeacherUser.role === 'admin'">
              <!--              <v-btn plain text color="#243674" class="students-table__action" @click="setEditedStudent(student)">-->
              <!--                Editar-->
              <!--              </v-btn>-->
              <v-btn plain text color="#D94343" class="students-table__action" @click="doDeleteStudent(studentKey)">
                Eliminar
              </v-btn>
            </template>
            <template v-if="myTeacherUser.role === 'teacher'">
              <v-simple-checkbox v-model="classStudents[studentKey].attended" v-ripple="false" />
            </template>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <form-button
      v-if="myTeacherUser.role === 'teacher' && myTeacherUser.id === classSelected.teacher.id" width="448px"
      height="50px" class="mt-7 mx-auto"
      elevation="0"
      :loading="saveFormLoading"
      @click="showPassToNextModal = true"
      @close="showPassToNextModal = false"
    >
      Guardar cambios
    </form-button>

    <pass-to-next-class-modal
      ref="passToNextClass"
      v-model="showPassToNextModal"
      :loading="loading"
      :date="nextSessionDateOfClass"
      @optionSelected="optionSelected"
      @close="showPassToNextModal=false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import formButton from '@/components/buttons/FormButton'
import passToNextClassModal from '@/components/modals/PassToNextClassModal'
import scheduleService from '@/services/scheduleService'
import calendarService from '@/services/calendarService'
import momentFunctions from '@/utils/momentFunctions'

export default {
  name: 'StudentsByClassTable',
  components: { formButton, passToNextClassModal },
  data () {
    return {
      tableHeads: ['', 'Nombre del estudiante', 'Correo electrónico', ''],
      showPassToNextModal: false,
      loading: false,
      saveFormLoading: false
    }
  },
  computed: {
    ...mapState('auth', ['myTeacherUser']),
    ...mapState('schedule', ['studentsByLesson', 'classSelected', 'currentLesson']),
    classStudents () {
      return this.classSelected.students
    },
    nextSessionDateOfClass () {
      return this.getNextDate(this.classSelected.startDate, this.classSelected.nextSessionDate)
    }
  },
  methods: {
    ...mapActions('schedule', ['deleteStudentFromClass', 'editClass']),
    async doDeleteStudent (studentKey) {
      const student = this.classSelected.students[studentKey]
      const message = `¿Está seguro de que desea eliminar el studiante ${student.firstName} ${student.lastName}?`
      const confirm = await this.$root.$confirm({
        message: message,
        confirmButtonText: 'Eliminar',
        type: 'alert'
      })
      if (confirm) {
        await this.deleteStudentFromClass(studentKey)
        await this.resetStudentAssistance(studentKey)
        await scheduleService.removeClassFromStudent(studentKey, this.classSelected.id)

        this.$root.$confirm({
          message: `${student.firstName} ${student.lastName} ha sido eliminado de la lista de estudiantes`,
          showDismissButton: false
        })
      }
    },

    async optionSelected (event) {
      try {
        this.loading = true
        if (event.classNotTaught) { // Repeat class with another date
          await this.passToNextClass(true, null, event.nextSessionDate, true)
        } else if (event.classCovered) { // Pass or repeat with another teacher
          await this.passToNextClass(event.optionSelected !== 0, event.teacher, event.nextSessionDate)
        } else { // Pass or repeat with the same teacher
          await this.passToNextClass(event.optionSelected !== 0, null, event.nextSessionDate)
        }
        this.$refs.passToNextClass.reset()
        this.loading = false
        this.showPassToNextModal = false
        this.$root.$confirm({ message: 'Se ha guardado los datos correctamente.' })
      } catch (e) {
        console.log(e)
        this.loading = false
        this.$root.$confirm({ message: 'Ha ocurrido un error. Inténte de nuevo en unos minutos.', type: 'alert' })
      }
    },
    async passToNextClass (repeatClass = false, teacher = null, nextSessionDate = null, cancelledClass = false) {
      // Create lesson
      const currentClass = this.classSelected
      const lessonStudents = {}
      Object.keys(currentClass.students).forEach((key) => {
        const classStudent = currentClass.students[key]
        lessonStudents[key] = {
          firstName: classStudent.firstName,
          lastName: classStudent.lastName,
          email: classStudent.email,
          guest: classStudent.guest,
          attended: classStudent.attended || false
        }
      })
      const lessonPayload = {
        class: {
          id: currentClass.id,
          day: currentClass.day,
          level: currentClass.level,
          teacher: currentClass.teacher,
          endHour: currentClass.endHour,
          startHour: currentClass.startHour,
          startDate: currentClass.startDate,
          originTimezone: currentClass.originTimezone
        },
        students: lessonStudents,
        week: currentClass.week,
        startDate: currentClass.nextSessionDate,
        createdAt: new Date(),
        state: 'DICTATED'
      }
      if (teacher) {
        lessonPayload.class.teacher = teacher
      }
      if (cancelledClass) {
        lessonPayload.state = 'CANCELLED'
      }
      await scheduleService.createLesson(lessonPayload)

      // Get information about the next class
      const nextClass = Object.assign({}, currentClass)
      delete nextClass.id
      if (!repeatClass) {
        if (currentClass.week === 8 && currentClass.level.name !== 'CONV' && currentClass.level.name !== 'INT') {
          const nextLevel = await scheduleService.getNextLevel(nextClass.level)
          nextClass.level = nextLevel || nextClass.level
          nextClass.week = 1
          // If pass to next level, update course name
          nextClass.name = this.buildCourseName(nextClass)
        } else {
          nextClass.week += 1
        }
      }

      if (!cancelledClass) {
        // Pass assistance and delete all guest student from class
        await Promise.all(
          Object.keys(nextClass.students).map(async key => {
            if (!nextClass.students[key].guest) {
              await this.passAssistanceToStudent(key, nextClass.students[key].attended)
              // Deleting changes made by the checkbox
              delete nextClass.students[key].attended
            } else {
              delete nextClass.students[key]
            }
          })
        )
      }

      if (nextSessionDate) {
        nextClass.nextSessionDate = nextSessionDate
      } else {
        nextClass.nextSessionDate = this.getNextDate(nextClass.startDate, nextClass.nextSessionDate)
      }
      nextClass.studentsIds = Object.keys(nextClass.students)

      // Update class
      await this.editClass({ classId: this.classSelected.id, updatedClass: nextClass })

      // Crate calendar event
      await this.createCalendarEvent(nextClass)
    },

    async passAssistanceToStudent (studentId, assistanceValue) {
      const student = await scheduleService.getStudent(studentId)
      let assistance = student.assistance || []
      assistance.push({
        value: assistanceValue || false,
        createdAt: new Date()
      })

      if (assistance.length > 5) {
        assistance = assistance.slice(assistance.length - 5, assistance.length)
      }

      const assistanceCounter = assistance.filter(item => item.value === true).length
      await scheduleService.editStudent(studentId, {
        assistance,
        assistanceCounter,
        active: true
      })
    },
    async resetStudentAssistance (studentId) {
      await scheduleService.editStudent(studentId, {
        assistanceCounter: -1,
        active: false
      })
    },
    getNextDate (classDate, lastSessionDate) {
      const lastLessonStartDate = new Date(lastSessionDate)
      const classStartDate = new Date(classDate)

      const nextDate = new Date(
        lastLessonStartDate.setDate(
          lastLessonStartDate.getDate() + ((7 - lastLessonStartDate.getUTCDay() + classStartDate.getUTCDay()) % 7 || 7)
        )
      )

      return `${nextDate.getUTCFullYear()}-${(nextDate.getUTCMonth() + 1)}-${nextDate.getUTCDate()}`
    },
    buildCourseName (classObject) {
      let courseName = ''
      if (classObject.level) {
        courseName = classObject.level.largeName
      }
      if (classObject.day) {
        courseName = `${courseName} - ${classObject.day}`
      }
      if (classObject.teacher) {
        courseName = `${courseName} con ${classObject.teacher.firstName}`
      }
      return courseName
    },

    async createCalendarEvent (classObject) {
      try {
        const utcStartHour = momentFunctions.createUtcMomentObjectFromLocaleDate(classObject.startHour, 'hh:mm A', classObject.originTimezone.timezone).format('hh:mm A')
        const startHour = momentFunctions.getLocaleDateFromUtcMomentObject(utcStartHour, 'hh:mm A', classObject.originTimezone.timezone).format('HH:mm:[00]')
        const utcEndHour = momentFunctions.createUtcMomentObjectFromLocaleDate(classObject.endHour, 'hh:mm A', classObject.originTimezone.timezone).format('hh:mm A')
        const endHour = momentFunctions.getLocaleDateFromUtcMomentObject(utcEndHour, 'hh:mm A', classObject.originTimezone.timezone).format('HH:mm:[00]')
        const attendees = Object.keys(classObject.students).map((key) => {
          return {
            email: classObject.students[key].email
          }
        })
        const payload = {
          summary: classObject.name,
          start: {
            dateTime: `${classObject.nextSessionDate}T${startHour}`,
            timeZone: classObject.originTimezone.timezone
          },
          end: {
            dateTime: `${classObject.nextSessionDate}T${endHour}`,
            timeZone: classObject.originTimezone.timezone
          },
          attendees
        }
        calendarService.createEvent(payload)
      } catch (e) {
        console.log(e)
      }
    }

  }
}
</script>

<style scoped lang="scss">
.students-table {
  th, td {
    border: none !important;
  }

  &__head-item {
    font-weight: 700 !important;
    font-size: 14px !important;
    color: #404040 !important;
  }

  &__item {
    font-weight: 400;
    font-size: 14px;
    color: #404040;
  }

  &__action {
    text-transform: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
  }
}
</style>
