<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col cols="8">
          <div class="go-to-back mb-5">
            <v-icon small class="mr-2" color="#0091CD" style="cursor: pointer;" @click="goToBack">
              mdi-arrow-left
            </v-icon>
            <span style="cursor: pointer;" @click="goToBack">
              Regresar a la lista de clases
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8">
          <p class="class__name mb-8">
            {{ $store.state.schedule.newClass ? `Clase: ${name}` : 'Clase' }}
          </p>
          <v-row>
            <v-col class="py-0" cols="9">
              <base-select v-model="level" :rules="[rules.required]" label="Nivel" :items="levels" return-object
                           item-value="id"
                           item-text="name"
              />
            </v-col>
            <v-col class="py-0" cols="3">
              <base-select v-model="day" :rules="[rules.required]" label="Día de clases"
                           :items="daysOfWeek" @input="filteredTeachers"
              />
            </v-col>
            <v-col class="py-0" cols="3">
              <base-text-field v-model="startHour" placeholder="00:00 PM" :rules="[rules.required]"
                               label="Hora de inicio" @keyup="filteredTeachers"
              />
            </v-col>
            <v-col class="py-0" cols="3">
              <base-text-field v-model="endHour" placeholder="00:00 PM" label="Hora de fin" :rules="[rules.required]"
                               @keyup="filteredTeachers"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <base-select v-model="originTimezone" :rules="[rules.required]" :items="timezones"
                           item-value="timezone" label="Timezone" return-object @input="filteredTeachers"
              >
                <template v-slot:selection="data">
                  {{ data.item.code }}
                </template>
                <template v-slot:item="data">
                  {{ data.item.code }}
                </template>
              </base-select>
            </v-col>
            <v-col class="py-0" cols="12">
              <base-select v-model="teacher" :items="teachers" item-value="id" item-disabled="disabled" label="Profesor"
                           return-object
                           :rules="[rules.required]"
              >
                <template v-slot:selection="data">
                  {{ data.item.firstName }} {{ data.item.lastName }}
                </template>
                <template v-slot:item="data">
                  {{ data.item.firstName }} {{ data.item.lastName }}
                </template>
              </base-select>
            </v-col>
            <v-col class="py-0" cols="12">
              <base-text-field v-model="name" label="Nombre de la clase" :rules="[rules.required]" />
            </v-col>
            <v-col class="py-0" cols="12">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <base-text-field
                    :value="$moment(startDate).isValid()?$moment(startDate).format('MMMM Do, YYYY'): ''"
                    v-bind="attrs" :rules="[rules.required]" label="Fecha de inicio de clases"
                    :placeholder="$moment().format('MMMM Do, YYYY')" readonly v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="dateMenu = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <div>
            <form-button width="200px" elevation="0" class="mb-5" :loading="loading" @click="save">
              Guardar cambios
            </form-button>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import BaseTextField from '@/components/inputs/BaseTextField'
import BaseSelect from '@/components/inputs/BaseSelect'
import FormButton from '@/components/buttons/FormButton'
import { mapActions } from 'vuex'
import { required } from '@/utils/rules'
import momentFunctions from '@/utils/momentFunctions'
import scheduleService from '@/services/scheduleService'

export default {
  name: 'AddClassForm',
  components: {
    BaseTextField,
    BaseSelect,
    FormButton
  },
  props: {
    loading: Boolean,
    isEditing: Boolean
  },
  data () {
    return {
      dateMenu: false,
      expand: true,
      disabled: false,
      readonly: true,
      levels: [],
      teachers: [],
      errors: {},
      rules: {
        required
      },
      daysOfWeek: [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo'
      ],
      timezones: [
        {
          code: 'PST',
          timezone: 'America/Los_Angeles'
        },
        {
          code: 'MST',
          timezone: 'America/Denver'
        },
        {
          code: 'CST',
          timezone: 'America/Chicago'
        },
        {
          code: 'EST',
          timezone: 'America/Detroit'
        }
      ]
    }
  },
  computed: {
    level: {
      get () {
        return this.$store.state.schedule.newClass.level
      },
      async set (value) {
        await this.setNewClassValue('level', value)
        await this.setNewClassValue('name', this.buildCourseName())
      }
    },
    teacher: {
      get () {
        return this.$store.state.schedule.newClass.teacher
      },
      async set (value) {
        await this.setNewClassValue('teacher', value)
        await this.setNewClassValue('name', this.buildCourseName())
      }
    },
    day: {
      get () {
        return this.$store.state.schedule.newClass.day
      },
      async set (value) {
        await this.setNewClassValue('day', value)
        await this.setNewClassValue('name', this.buildCourseName())
      }
    },
    originTimezone: {
      get () {
        return this.$store.state.schedule.newClass.originTimezone
      },
      async set (value) {
        await this.setNewClassValue('originTimezone', value)
      }
    },
    name: {
      get () {
        return this.$store.state.schedule.newClass.name
      },
      async set (value) {
        await this.setNewClassValue('name', value)
      }
    },
    startHour: {
      get () {
        return this.$store.state.schedule.newClass.startHour
      },
      async set (value) {
        await this.setNewClassValue('startHour', value)
      }
    },
    endHour: {
      get () {
        return this.$store.state.schedule.newClass.endHour
      },
      async set (value) {
        await this.setNewClassValue('endHour', value)
      }
    },
    startDate: {
      get () {
        return this.$store.state.schedule.newClass.startDate
      },
      async set (value) {
        await this.setNewClassValue('startDate', value)
      }
    },
    studentsIds () {
      return this.$store.state.schedule.newClass.studentsIds
    }
  },
  async beforeMount () {
    if (!this.$store.state.schedule.newClass) {
      const startDate = this.$moment().format('YYYY-MM-DD')
      await this.setNewClass({
        startDate,
        name: 'Beginners Three ',
        level: null,
        teacher: null,
        startHour: '07:00 PM',
        endHour: '08:30 PM',
        originTimezone: {
          code: 'PST',
          timezone: 'America/Los_Angeles'
        },
        timeout: null,
        week: 1,
        students: {},
        studentsIds: []
      })
    }

    this.levels = await scheduleService.getAllLevels()
    this.teachers = await scheduleService.getAllTeachers()
    await this.filteredTeachers()
  },
  methods: {
    ...mapActions('schedule', ['setNewClass', 'addWeekToNewClass']),
    goToBack () {
      this.$router.back()
    },
    async setNewClassValue (field, value) {
      const newClass = {
        ...this.$store.state.schedule.newClass
      }
      newClass[field] = value
      await this.setNewClass(newClass)
    },
    save () {
      if (this.validateForm()) {
        this.$emit('save', this.$store.state.schedule.newClass)
      }
    },
    validateForm () {
      const formValidation = this.$refs.form.validate()
      return formValidation
    },
    async filteredTeachers () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        const currentClass = this.$store.state.schedule.newClass
        const utcNewClassStartHour = momentFunctions.createUtcMomentObjectFromLocaleDate(currentClass.startHour, 'hh:mm A', currentClass.originTimezone.timezone)
        const utcNewClassEndHour = momentFunctions.createUtcMomentObjectFromLocaleDate(currentClass.endHour, 'hh:mm A', currentClass.originTimezone.timezone)

        this.teachers = await Promise.all(
          this.teachers.map(async (teacher) => {
            const classes = await scheduleService.getClassByTeacher(teacher)
            teacher.disabled = false
            classes.map((classObject) => {
              if (classObject.day === currentClass.day) {
                const utcClassStartHour = momentFunctions.createUtcMomentObjectFromLocaleDate(classObject.startHour, 'hh:mm A', classObject.originTimezone.timezone)
                const utcClassEndHour = momentFunctions.createUtcMomentObjectFromLocaleDate(classObject.endHour, 'hh:mm A', classObject.originTimezone.timezone)

                if (((utcClassStartHour <= utcNewClassStartHour && utcNewClassStartHour < utcClassEndHour) || (utcClassStartHour < utcNewClassEndHour && utcNewClassEndHour < utcClassEndHour)) && (classObject.id !== currentClass.id)) {
                  teacher.disabled = true
                }
              }
            })
            return teacher
          })
        )
      }, 800)
    },
    buildCourseName () {
      let courseName = ''
      if (this.level) {
        courseName = this.level.largeName
      }
      if (this.day) {
        courseName = `${courseName} - ${this.day}`
      }
      if (this.teacher) {
        courseName = `${courseName} con ${this.teacher.firstName}`
      }
      return courseName
    }
  }

}
</script>

<style scoped lang="scss">
.class {
  &__name {
    font-weight: 800;
    font-size: 36px;
    line-height: 38px;
    color: #243674;
  }
}

.go-to-back {
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  color: #0091CD;
  display: flex;
  align-items: center;
}

.form-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 35px;
  color: #243674;
  margin-bottom: 20px;
}

.preview {
  &__container {
    border: 1px solid #D5D5D5;
    padding: 35px 25px;
    border-radius: 15px;
  }

  &__label {
    font-weight: 600;
    font-size: 16px;
    line-height: 13px;
    color: #C4C4C4;
    margin-bottom: 20px;
  }
}

.cover-image-input {
  border: 1px solid #D5D5D5;
  padding: 20px 25px;
  border-radius: 15px;
}

.cover-image-input ::v-deep .cover-image-input__container {
  width: 100%;
}

.cover-image-input ::v-deep .base-drag-input {
  width: 100%;
  height: 351px;
}

.material-item ::v-deep .v-expansion-panel::before {
  box-shadow: none;
}

</style>
