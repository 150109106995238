<template>
  <v-card
    v-if="classItem"
    class="d-flex justify-space-around align-center class-card"
    :class="{'class-card--dense': dense, 'class-card--active': classSelected && classItem.id === classSelected.id }"
    :color="classItem.color"
    @click="$emit('click')"
  >
    <p class="mb-0">
      {{ classItem.name }}
    </p>
    <p class="mb-0">
      {{ classItem.studentsAmount }}
    </p>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ClassChip',
  props: {
    classItem: Object,
    dense: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    ...mapState('schedule', ['classSelected'])
  }
}
</script>

<style lang="scss" scoped>
.class-card {
  color: white !important;
  max-width: 80px;
  min-width: 80px;
  max-height: 40px;
  min-height: 40px;
  height: 40px;
  border-radius: 10px !important;
  margin: 3px auto;

  &--dense {
    max-height: 28px;
    min-height: 28px;
    height: 28px;
  }

  &--active {
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.49) !important;
    -webkit-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.49) !important;
    -moz-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.49) !important;
  }
}
</style>
