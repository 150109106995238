<template>
  <div>
    <div class="d-flex flex-column">
      <tslc-table :heads="tableHead" :items="sortItems" @sort="sort">
        <template v-slot:name="{item}">
          {{ item.name }}
        </template>

        <template v-slot:levelName="{item}">
          <v-card :color="item.level.color" class="class-chip">
            {{ item.level.name }}
          </v-card>
        </template>

        <template v-slot:schedule="{item}">
          {{ item.startHour }} a {{ item.endHour }} - {{ item.originTimezone.code }}
        </template>

        <template v-slot:numberOfStudents="{item}">
          <span style="cursor: pointer; text-decoration: underline;" @click="classSelected = item; showClassStudentsModal = true">
            {{ item.studentsIds.length }}
          </span>
        </template>

        <template v-slot:week="{ item }">
          {{ item.level.name !== 'INT' && item.level.name !== 'CONV' ? `Semana ${item.week}` : `-` }}
        </template>

        <template v-slot:startDate="{ item }">
          {{ item.startDate | moment('MMMM Do, YYYY') }}
        </template>

        <template v-slot:actions="{item}">
          <v-btn
            text color="#243674" class="tslc-table__action"
            :to="{name: 'EditClass', params: {id: item.id}}"
          >
            Editar
          </v-btn>
          <v-btn
            text color="#D94343" class="tslc-table__action" @click="doDeleteClass(item)"
          >
            Eliminar
          </v-btn>
        </template>
      </tslc-table>
      <class-students-modal v-if="classSelected" v-model="showClassStudentsModal" :class-item="classSelected" @close="showClassStudentsModal=false; classSelected=null" />
    </div>
  </div>
</template>

<script>
import tslcTable from '@/components/TslcTable'
import { mapActions, mapState } from 'vuex'
import momentFunctions from '@/utils/momentFunctions'
import classStudentsModal from '@/components/modals/ClassStudentsModal'

export default {
  name: 'ClassesListTable',
  components: {
    tslcTable,
    classStudentsModal
  },
  data () {
    return {
      tableHead: [
        {
          name: 'Nombre de clase',
          value: 'name',
          sortBy: 'name'
        },
        {
          name: 'Nivel',
          value: 'levelName',
          sortBy: 'level'
        },
        {
          name: 'Día',
          value: 'day',
          sortBy: 'day'
        },
        {
          name: 'Horario',
          value: 'schedule',
          sortBy: 'schedule'
        },
        {
          name: 'Semana',
          value: 'week',
          sortBy: 'week'
        },
        {
          name: 'N de estudiantes',
          value: 'numberOfStudents',
          sortBy: 'numberOfStudents'
        },
        {
          name: 'Fecha de inicio',
          value: 'startDate',
          sortBy: 'startDate'
        },
        {
          name: '',
          value: 'actions'
        }
      ],
      sortBy: 'created_at',
      orientation: 'desc',
      daysWeek: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      showClassStudentsModal: false,
      classSelected: null
    }
  },
  computed: {
    ...mapState('schedule', ['allClasses']),
    sortItems () {
      const newArray = this.allClasses
      return newArray.sort((a, b) => {
        const sortOrder = this.orientation === 'desc' ? -1 : 1
        if (this.sortBy === 'name') {
          const aName = a.name || 'z'
          const bName = b.name || 'z'
          const result = aName < bName ? -1 : aName > bName ? 1 : 0
          return result * sortOrder
        } else if (this.sortBy === 'level') {
          const result = (a.level.name < b.level.name) ? -1 : (a.level.name > b.level.name) ? 1 : 0
          return result * sortOrder
        } else if (this.sortBy === 'day') {
          const result = this.daysWeek.indexOf(a.day) < this.daysWeek.indexOf(b.day) ? -1 : this.daysWeek.indexOf(a.day) > this.daysWeek.indexOf(b.day) ? 1 : 0
          return result * sortOrder
        } else if (this.sortBy === 'schedule') {
          const result = momentFunctions.createUtcMomentObjectFromLocaleDate(a.startHour, 'hh:mm A', a.originTimezone.timezone) < momentFunctions.createUtcMomentObjectFromLocaleDate(b.startHour, 'hh:mm A', b.originTimezone.timezone) ? -1 : momentFunctions.createUtcMomentObjectFromLocaleDate(a.startHour, 'hh:mm A', a.originTimezone.timezone) > momentFunctions.createUtcMomentObjectFromLocaleDate(b.startHour, 'hh:mm A', b.originTimezone.timezone) ? 1 : 0
          return result * sortOrder
        } else if (this.sortBy === 'week') {
          const aWeek = a.level.name === 'CONV' || a.level.name === 'INT' ? 0 : a.week
          const bWeek = b.level.name === 'CONV' || b.level.name === 'INT' ? 0 : b.week
          const result = aWeek < bWeek ? -1 : aWeek > bWeek ? 1 : 0
          return result * sortOrder
        } else if (this.sortBy === 'startDate') {
          const aStartDate = this.$moment(a.startDate)
          const bStartDate = this.$moment(b.startDate)
          const result = aStartDate < bStartDate ? -1 : aStartDate > bStartDate ? 1 : 0
          return result * sortOrder
        } else if (this.sortBy === 'numberOfStudents') {
          const aLenght = a.studentsIds.length
          const bLenght = b.studentsIds.length
          const result = aLenght < bLenght ? -1 : aLenght > bLenght ? 1 : 0
          return result * sortOrder * -1
        }
      })
    }
  },
  async mounted () {
    await this.getAllClasses()
  },
  methods: {
    ...mapActions('schedule', ['getAllClasses', 'deleteClass']),
    async doDeleteClass (item) {
      const message = `¿Está seguro de que desea eliminar el curso ${item.level.name}?`
      const confirm = await this.$root.$confirm({
        message: message,
        confirmButtonText: 'Eliminar',
        type: 'alert'
      })
      if (confirm) {
        await this.deleteClass(item)
        this.$root.$confirm({
          message: `${item.level.name} ha sido eliminado de la lista de cursos`,
          showDismissButton: false
        })
      }
    },
    sort ({ sortBy, orientation }) {
      this.sortBy = sortBy
      this.orientation = orientation
    }
  }
}
</script>

<style scoped lang="scss">
.class-chip {
  width: 136px;
  height: 42px;
  border-radius: 10px !important;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px 0;
}

</style>
