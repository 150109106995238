<template>
  <v-simple-table style="width: 100%; height: 100%;" fixed-header class="classes-table" max-height="70vh">
    <thead class="classes-table__head">
      <tr>
        <th class="classes-table__head-item" />
        <th v-for="(th, i) in tableHead" :key="i" class="text-center classes-table__head-item" v-text="th" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="(classObject, index) in classesByTeacher" :key="`classObject-${index}`">
        <td class="classes-table__head-item">
          {{ classObject.teacher.firstName }} {{ classObject.teacher.lastName }}
        </td>
        <td v-for="(day, dayIndex) in classObject.days" :key="`classObject-${index}-day-${dayIndex}`"
            class="flex flex-column justify-center align-center"
        >
          <class-chip v-for="(classItem, classIndex) in day.classes" :key="`class-${classIndex}`"
                      :dense="day.classes.length > 1" :class-item="classItem"
                      :class="{'my-3': day.classes.length === 1}"
                      @click="selectCurrentClass(classItem.id)"
          />
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import classChip from './ClassChip'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'ClassesTable',
  components: {
    classChip
  },
  data () {
    return {
      tableHead: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado']
    }
  },
  computed: {
    ...mapState('schedule', ['classSelected']),
    ...mapGetters('schedule', ['scheduleClasses']),
    classesByTeacher () {
      const classesByTeacher = []
      this.scheduleClasses.map((classObject) => {
        const teacherIndex = classesByTeacher.findIndex((classByTeacherItem) => classByTeacherItem.teacher.id === classObject.teacher.id)
        let classByTeacherItem = {}
        if (teacherIndex === -1) {
          classByTeacherItem = {
            teacher: classObject.teacher,
            days: [
              { day: 'Lunes', classes: [] },
              { day: 'Martes', classes: [] },
              { day: 'Miércoles', classes: [] },
              { day: 'Jueves', classes: [] },
              { day: 'Viernes', classes: [] },
              { day: 'Sábado', classes: [] },
              { day: 'Domingo', classes: [] }
            ]
          }
          classesByTeacher.push(classByTeacherItem)
        } else {
          classByTeacherItem = classesByTeacher[teacherIndex]
        }
        // Find day of class and set in class of day array
        const dayIndex = classByTeacherItem.days.findIndex(day => day.day === classObject.day)
        if (dayIndex !== -1) {
          classByTeacherItem.days[dayIndex].classes.push({
            id: classObject.id,
            name: classObject.level.name,
            color: classObject.level.color,
            studentsAmount: classObject.studentsIds ? classObject.studentsIds.length : 0
          })
        }
      })
      return classesByTeacher
    }
  },
  async mounted () {
    await this.getAllClasses()
  },
  methods: {
    ...mapActions('schedule', ['getAllClasses', 'setClassSelected']),
    selectCurrentClass (classId) {
      if (!this.classSelected || classId !== this.classSelected.id) {
        const newClassSelected = this.scheduleClasses.find(classItem => classItem.id === classId)
        this.setClassSelected(newClassSelected)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.classes-table {
  background: #F9F9F9 !important;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #AFAFAF;
  overflow: hidden;

  &__head-item {
    color: #404040 !important;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 16px;
    background: #F9F9F9 !important;
  }
}

</style>
