<template>
  <base-modal v-bind="$attrs" width="660px" :show-dismiss-button="false" v-on="$listeners"
              @click:outside="$emit('close')"
  >
    <template v-slot:body>
      <v-row>
        <v-col class="py-0" cols="12">
          <v-img width="140px" height="140px" class="mx-auto mb-2" src="/img/alert-animation.gif" />
        </v-col>
      </v-row>
    </template>

    <template v-slot:subtitle>
      <div class="mb-4">
        <p class="mb-0">
          ¿Sucedió algo extraordinario?
        </p>
        <v-checkbox v-model="classNotTaught" hide-details label="No se dictó la clase" />
        <v-expand-transition>
          <div v-show="classNotTaught" />
        </v-expand-transition>
        <v-checkbox v-model="classCovered" hide-details label="Clase fue cubierta por:" />
        <v-expand-transition>
          <div v-show="classCovered">
            <v-form ref="classCoveredForm">
              <base-select
                v-model="teacher" class="text-left" :items="teachers" item-value="id" item-disabled="disabled"
                label="Profesor*"
                return-object
                :rules="[rules.required]"
                :hide-details="false"
              >
                <template v-slot:selection="data">
                  {{ data.item.firstName }} {{ data.item.lastName }}
                </template>
                <template v-slot:item="data">
                  {{ data.item.firstName }} {{ data.item.lastName }}
                </template>
              </base-select>
            </v-form>
          </div>
        </v-expand-transition>
      </div>

      <template v-if="classSelected.level.name === 'CONV' || classSelected.level.name === 'INT'">
        <p class="mb-0">
          Seleccione la fecha de la siguiente semana
        </p>
      </template>
      <template v-else>
        <p class="mb-0">
          Estamos finalizando la semana {{ classSelected.week }}. ¿Qué se hará la próxima semana?
        </p>
      </template>
      <base-select
        v-show="classSelected.level.name !== 'CONV' && classSelected.level.name !== 'INT'"
        v-model="semanaValue" class="mt-3" style="margin-bottom: 0!important" item-value="value" return-object
        :items="items"
        hide-details
      />
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <base-text-field
            class="base-text-field"
            hide-details
            :value="$moment(nextSessionDate).isValid()?$moment(nextSessionDate).format('MMMM Do, YYYY'): ''"
            v-bind="attrs" :rules="[rules.required]" label="Fecha de próxima clase"
            :placeholder="$moment().format('MMMM Do, YYYY')" readonly v-on="on"
          />
        </template>
        <v-date-picker
          v-model="nextSessionDate"
          @input="dateMenu = false"
        />
      </v-menu>
    </template>

    <template v-slot:actions>
      <form-button elevation="0" width="307px" height="50px" class="mt-2 mb-2 mx-auto" :loading="loading"
                   @click="save()"
      >
        Guardar cambios
      </form-button>
    </template>
  </base-modal>
</template>

<script>
import baseModal from '@/components/modals/BaseModal'
import baseSelect from '@/components/inputs/BaseSelect'
import formButton from '@/components/buttons/FormButton'
import { mapState } from 'vuex'
import baseTextField from '@/components/inputs/BaseTextField'
import { required } from '@/utils/rules'
import scheduleService from '@/services/scheduleService'

export default {
  name: 'PassToNextClassModal',
  components: {
    baseModal,
    baseSelect,
    formButton,
    baseTextField
  },
  props: {
    loading: Boolean,
    date: String
  },
  data () {
    return {
      semanaValue: { value: 0 },
      classNotTaught: false,
      classCovered: false,
      nextSessionDate: this.date,
      dateMenu: false,
      rules: {
        required
      },
      teachers: [],
      teacher: null
    }
  },
  computed: {
    ...mapState('schedule', ['classSelected']),
    items () {
      const itemsArray = [
        {
          value: 0,
          text: `Pasar a la semana ${this.classSelected.week + 1}`
        },
        {
          value: 1,
          text: `Repaso de semana ${this.classSelected.week}`
        }
      ]
      if (this.classSelected.week === 8) {
        itemsArray[0].text = 'Pasar al siguiente nivel'
      }
      return itemsArray
    }
  },
  watch: {
    classNotTaught (value) {
      if (this.classCovered === true && value === true) {
        this.classCovered = false
      }
    },
    classCovered (value) {
      if (this.classNotTaught === true && value === true) {
        this.classNotTaught = false
      }
    },
    date (value) {
      this.nextSessionDate = value
      this.semanaValue.value = 0
    }
  },
  async mounted () {
    this.teachers = await scheduleService.getAllTeachers()
  },
  methods: {
    save () {
      if (this.classCovered && !this.$refs.classCoveredForm.validate()) {
        return
      }
      const payload = {
        optionSelected: this.semanaValue.value,
        nextSessionDate: this.nextSessionDate
      }
      if (this.classNotTaught) {
        payload.classNotTaught = true
      }
      if (this.classCovered) {
        payload.classCovered = true
        payload.teacher = {
          id: this.teacher.id,
          firstName: this.teacher.firstName,
          lastName: this.teacher.lastName,
          email: this.teacher.email
        }
      }
      this.$emit('optionSelected', payload)
    },
    reset () {
      this.classCovered = false
      this.classNotTaught = false
      this.teacher = null
    }
  }
}
</script>

<style scoped lang="scss">
.base-text-field::v-deep {
  text-align: left !important;
  font-weight: 600 !important;
}

::v-deep .label {
  font-weight: 600 !important;
}
</style>
