<template>
  <div>
    <tslc-table :heads="tableHead" :items="teachers" @sort="sort">
      <template v-slot:password="{item}">
        <div class="d-flex justify-space-between align-center">
          {{ item.showPassword ? item.password : hidePassword(item.password) }}
          <v-icon v-if="myTeacherUser.role === 'admin' || myTeacherUser.uid === item.uid" class="mx-2" @click="item.showPassword = !item.showPassword">
            {{ item.showPassword ? "mdi-eye-off" : 'mdi-eye' }}
          </v-icon>
        </div>
      </template>
      <template v-slot:actions="{item}">
        <v-btn v-if="myTeacherUser.role === 'admin' || myTeacherUser.uid === item.uid" text color="#243674" class="tslc-table__action" @click="setEditedTeacher(item)">
          Editar
        </v-btn>
        <v-btn v-if="myTeacherUser.role === 'admin'" text color="#D94343" class="tslc-table__action" @click="doDeleteTeacher(item)">
          Eliminar
        </v-btn>
        <v-btn :disabled="!(myTeacherUser.role === 'admin' || myTeacherUser.uid === item.uid)" color="#243674" elevation="0" class="tslc-table__action white--text mx-2" @click="selectedTeacher = item; showTeachingAssistanceModal = true">
          Ver reporte
        </v-btn>
      </template>
    </tslc-table>
    <infinite-loading ref="teacherinfinite" @infinite="infiniteHandler">
      <template v-slot:no-more>
        <div />
      </template>
      <template v-slot:no-results>
        <div />
      </template>
    </infinite-loading>
    <teaching-assistance-modal v-model="showTeachingAssistanceModal" :teacher="selectedTeacher"
                               @click:outside="closeViewReportModal" @close="closeViewReportModal"
    />
  </div>
</template>

<script>
import tslcTable from '@/components/TslcTable'
import teachingAssistanceModal from '@/components/modals/TeachingAssistanceModal'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TeacheresListTable',
  components: {
    tslcTable,
    teachingAssistanceModal
  },
  data () {
    return {
      tableHead: [
        {
          name: 'Nombre',
          value: 'firstName',
          sortBy: 'firstName'
        },
        {
          name: 'Apellido',
          value: 'lastName',
          sortBy: 'lastName'
        },
        {
          name: 'Correo electrónico',
          value: 'email',
          sortBy: 'email'
        },
        {
          name: 'Contraseña',
          value: 'password'
        },
        {
          name: '',
          value: 'actions'
        }
      ],
      showTeachingAssistanceModal: false,
      selectedTeacher: null,
      sortBy: 'created_at',
      orientation: 'desc'
    }
  },
  computed: {
    ...mapState('schedule', ['existNextTeachers', 'teachers']),
    ...mapState('auth', ['existNextTeachers', 'myTeacherUser'])
  },
  async destroyed () {
    await this.resetTeachers()
  },
  methods: {
    ...mapActions('schedule', ['getTeachers', 'setEditedTeacher', 'deleteTeacher', 'resetTeachers']),
    async infiniteHandler ($state) {
      await this.getTeachers({
        orderByField: this.sortBy,
        orientation: this.orientation
      })
      this.existNextTeachers ? $state.loaded() : $state.complete()
    },
    hidePassword (password) {
      return password.replace(/[A-Za-z0-9!@#$%^&*()_+\-=]/g, '*')
    },
    async doDeleteTeacher (item) {
      const message = `¿Está seguro de que desea eliminar al profesor ${item.firstName} ${item.lastName}?`
      const confirm = await this.$root.$confirm({
        message: message,
        confirmButtonText: 'Eliminar',
        type: 'alert'
      })
      if (confirm) {
        try {
          await this.deleteTeacher(item)
          this.$root.$confirm({
            message: `${item.firstName} ${item.lastName} ha sido eliminado de la lista de profesores`,
            showDismissButton: false
          })
        } catch (e) {
          this.$root.$confirm({
            message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
            type: 'alert'
          })
        }
      }
    },
    closeViewReportModal () {
      this.showTeachingAssistanceModal = false
      this.selectedTeacher = null
    },
    async resetInfiniteLoading () {
      await this.$refs.teacherinfinite.stateChanger.reset()
      await this.resetTeachers()
    },
    async sort ({ sortBy, orientation }) {
      this.sortBy = sortBy
      this.orientation = orientation
      await this.resetInfiniteLoading()
    }
  }
}
</script>

<style scoped lang="scss">
.class-chip {
  width: 136px;
  height: 42px;
  border-radius: 10px !important;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px 0;
}

</style>
