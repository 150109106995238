<template>
  <v-card class="class-legend">
    <div>
      <p class="class-legend__title">
        REFERENCIAS
      </p>
      <div class="references-container">
        <div v-for="(level, index) in levels" :key="index" class="level-item">
          <v-chip class="level-item__chip" :color="level.color" />
          <p class="level-item__text">
            {{ level.name }}
          </p>
        </div>
        <v-spacer style="max-width: 100px; min-width: 100px; margin: auto" />
      </div>
    </div>
    <v-divider />
    <div class="mt-10">
      <p class="class-legend__title">
        CURRENT TIME
      </p>
      <div class="d-flex justify-space-around">
        <div class="time-item">
          <p class="time-item__name">
            PST
          </p>
          <p class="time-item__time">
            {{ currentDate | moment('timezone', 'America/Los_Angeles', 'hh:mm A') }}
          </p>
        </div>
        <div class="time-item">
          <p class="time-item__name">
            MST
          </p>
          <p class="time-item__time">
            {{ currentDate | moment('timezone', 'America/Denver', 'hh:mm A') }}
          </p>
        </div>
        <div class="time-item">
          <p class="time-item__name">
            CST
          </p>
          <p class="time-item__time">
            {{ currentDate | moment('timezone', 'America/Chicago', 'hh:mm A') }}
          </p>
        </div>
        <div class="time-item">
          <p class="time-item__name">
            EST
          </p>
          <p class="time-item__time">
            {{ currentDate | moment('timezone', 'America/Detroit', 'hh:mm A') }}
          </p>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import scheduleService from '@/services/scheduleService'

export default {
  name: 'ClassLegend',
  data () {
    return {
      levels: [],
      currentDate: new Date()
    }
  },
  async mounted () {
    this.levels = await scheduleService.getAllLevels()
  }
}
</script>

<style scoped lang="scss">

.class-legend {
  background: #F9F9F9;
  border: 1px solid #AFAFAF;
  box-sizing: border-box;
  border-radius: 10px;
  width: 518px;
  height: 367px;
  padding: 10px 30px;

  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #404040;
    margin-bottom: 17px;
    text-align: center;
  }
}

.references-container {
  padding: 15px 25px;
  display: flex;
  flex-wrap: wrap;
}

.level-item {
  display: flex;
  align-items: center;
  max-width: 110px;
  min-width: 110px;
  margin: 12px auto;

  &__chip {
    height: 30px;
    width: 30px;
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
    margin-right: 12px;
    margin-left: 12px;
  }
}

.time-item {
  text-align: center;
  color: #404040;

  &__name {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__time {
    font-weight: 400;
    font-size: 14px;
  }
}

</style>
