<template>
  <v-container fluid>
    <v-main class="mx-auto ">
      <add-class-form ref="addForm" class="ma-15" :loading="loading" @save="save" />
    </v-main>
  </v-container>
</template>

<script>
import AddClassForm from '@/components/schedule/AddClassForm'
import { mapActions } from 'vuex'

export default {
  name: 'AddClass',
  components: {
    AddClassForm
  },
  data () {
    return {
      loading: false
    }
  },
  async mounted () {
    const startDate = this.$moment().format('YYYY-MM-DD')
    await this.setNewClass({
      startDate,
      name: 'Beginners One',
      level: null,
      teacher: null,
      startHour: '07:00 PM',
      endHour: '08:30 PM',
      originTimezone: {
        code: 'PST',
        timezone: 'America/Los_Angeles'
      },
      studentsIds: [],
      students: {},
      week: 1
    })
  },
  methods: {
    ...mapActions('schedule', ['addClass', 'setNewClass']),
    async save (newClass) {
      if (this.$refs.addForm.validateForm()) {
        try {
          this.loading = true
          const payload = {
            name: newClass.name,
            level: newClass.level,
            created_at: new Date(),
            day: newClass.day,
            startHour: newClass.startHour,
            endHour: newClass.endHour,
            originTimezone: newClass.originTimezone,
            teacher: {
              lastName: newClass.teacher.lastName,
              firstName: newClass.teacher.firstName,
              email: newClass.teacher.email,
              id: newClass.teacher.id
            },
            startDate: newClass.startDate,
            week: newClass.week || 1,
            studentsIds: [],
            students: {},
            nextSessionDate: newClass.startDate
          }
          await this.addClass(payload)
          this.loading = false
          await this.$root.$confirm({
            message: `Se ha agregado '${newClass.name}' a la lista de cursos`,
            showDismissButton: false
          })
          await this.$router.push({ name: 'Schedule' })
        } catch (e) {
          console.log(e)
          await this.$root.$confirm({
            message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
            showDismissButton: false,
            type: 'alert'
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
