<template>
  <div>
    <v-skeleton-loader v-if="lessonLoading" type="image" class="class-detail" />
    <v-card v-else-if="classSelected" :color="classSelected.level.color" class="class-detail d-flex justify-space-around align-center">
      <p class="class-detail__title">
        {{ classSelected.level.name }}
      </p>
      <div>
        <div class="d-flex justify-space-between">
          <p class="class-detail__item">
            {{ classSelected.day }}
          </p>
          <p class="class-detail__item">
            {{ classSelected.originTimezone.code }}
          </p>
        </div>
        <div>
          {{ classSelected.startHour }}
          a
          {{ classSelected.endHour }}
        </div>
      </div>
      <p class="class-detail__item">
        {{ classSelected.teacher.firstName }} {{ classSelected.teacher.lastName }}
      </p>
      <p class="class-detail__subtitle" :class="{'d-none': classSelected.level.name === 'CONV' || classSelected.level.name === 'INT'}">
        Semana {{ classSelected.week }}
      </p>
      <p class="class-detail__item">
        {{ classSelected.nextSessionDate | moment("MMMM Do, YYYY") }}
      </p>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import momentFunctions from '@/utils/momentFunctions'

export default {
  name: 'ClassDetail',
  data () {
    return {
      momentFunctions
    }
  },
  computed: {
    ...mapState('schedule', ['lessonLoading', 'classSelected'])
  }
}
</script>

<style scoped lang="scss">

.class-detail {
  color: white;
  height: 56px;
  border-radius: 10px;

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 0;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 0;
  }

  &__item {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 0;
  }
}

</style>
