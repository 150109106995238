<template>
  <v-container fluid>
    <v-main class="mx-auto ">
      <add-class-form
        ref="editForm" :is-editing="true"
        class="ma-15" :loading="loading" @save="save"
      />
    </v-main>
  </v-container>
</template>

<script>
import AddClassForm from '@/components/schedule/AddClassForm'
import { mapActions } from 'vuex'
import scheduleService from '@/services/scheduleService'

export default {
  name: 'EditClass',
  components: {
    AddClassForm
  },
  data () {
    return {
      loading: false
    }
  },
  async mounted () {
    const currentClass = await scheduleService.getClassById(this.$route.params.id)
    if (!currentClass.weeks) currentClass.weeks = []
    await this.setNewClass(currentClass)
  },
  methods: {
    ...mapActions('schedule', ['editClass', 'setNewClass']),
    async save (newClass) {
      if (this.$refs.editForm.validateForm()) {
        try {
          this.loading = true
          const payload = {
            classId: newClass.id,
            updatedClass: {
              name: newClass.name,
              day: newClass.day,
              startHour: newClass.startHour,
              endHour: newClass.endHour,
              originTimezone: newClass.originTimezone,
              teacher: {
                id: newClass.teacher.id,
                firstName: newClass.teacher.firstName,
                lastName: newClass.teacher.lastName,
                email: newClass.teacher.email
              },
              level: {
                id: newClass.level.id,
                name: newClass.level.name,
                color: newClass.level.color
              },
              startDate: newClass.startDate,
              week: newClass.week || 1,
              studentsAmount: newClass.studentsAmount || 0,
              students: newClass.students || {}
            }
          }
          if ((newClass.level.name === 'B1' && payload.week === 1) || !payload.updatedClass.nextSessionDate) {
            payload.updatedClass.nextSessionDate = newClass.startDate
          }
          await this.editClass(payload)
          this.loading = false
          await this.$root.$confirm({
            message: `Se ha editaco correctamente '${newClass.name}'`,
            showDismissButton: false
          })
          await this.$router.push({ name: 'Schedule' })
        } catch (e) {
          console.log(e)
          await this.$root.$confirm({
            message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
            showDismissButton: false,
            type: 'alert'
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
