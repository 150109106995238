<template>
  <base-modal v-bind="$attrs" width="660px" v-on="$listeners" @click:outside="cleanFields">
    <template v-slot:activator="{ attrs, on }">
      <form-button
        v-bind="attrs"
        width="448px" height="50px" class="mt-15 mx-auto" v-on="on"
      >
        Agregar nuevo profesor
      </form-button>
    </template>

    <template v-slot:title>
      {{ isEditing ? `Editar profesor` : 'Agregar nuevo profesor' }}
    </template>

    <template v-slot:body>
      <v-form ref="form"
              v-model="valid"
              lazy-validation
      >
        <v-row>
          <v-col class="py-0" cols="12">
            <base-text-field v-model="newTeacher.firstName" :rules="[rules.required]" label="Nombre" />
          </v-col>
          <v-col class="py-0" cols="12">
            <base-text-field v-model="newTeacher.lastName" :rules="[rules.required]" label="Apellidos" />
          </v-col>
          <v-col class="py-0" cols="12">
            <base-text-field v-model="newTeacher.email" :rules="[rules.required, rules.email]" label="Correo electrónico" />
          </v-col>
          <v-col class="py-0" cols="12">
            <base-text-field v-model="newTeacher.password" :type="showPassword ? 'text' : 'password'" :rules="[rules.required, rules.min(6)]" label="Contraseña" :append-icon="showPassword ? 'mdi-eye-off': 'mdi-eye'" @click:append="passwordToggle" />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:actions>
      <form-button elevation="0" width="307px" height="50px" class="mt-5 mb-2 mx-auto" :loading="saving"
                   @click="saveTeacher()"
      >
        Guardar cambios
      </form-button>
    </template>
  </base-modal>
</template>

<script>
import baseModal from '@/components/modals/BaseModal'
import formButton from '@/components/buttons/FormButton'
import BaseTextField from '@/components/inputs/BaseTextField'
import { mapActions, mapState } from 'vuex'
import { email, min, required } from '@/utils/rules'

export default {
  name: 'NewTeacherModal',
  components: {
    BaseTextField,
    baseModal,
    formButton
  },
  inheritAttrs: false,
  data () {
    return {
      timezones: ['CST', 'PST', 'TMS'],
      valid: true,
      saving: false,
      showPassword: false,
      newTeacher: {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },
      rules: {
        required,
        email,
        min
      }
    }
  },
  computed: {
    ...mapState('schedule', ['editedTeacher']),
    isEditing () {
      return !!this.editedTeacher
    }
  },
  watch: {
    editedTeacher (newValue) {
      if (this.isEditing) {
        this.newTeacher = { ...newValue }
      }
    }
  },
  methods: {
    ...mapActions('schedule', ['addTeacher', 'editTeacher']),
    passwordToggle () {
      this.showPassword = !this.showPassword
    },
    cleanFields () {
      this.newTeacher = {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      }
      this.$refs.form.resetValidation()
    },
    async saveTeacher () {
      if (this.$refs.form.validate()) {
        try {
          this.saving = true
          if (this.isEditing) {
            if (this.editedTeacher.password !== this.newTeacher.password) {
              // Modify user password only if user password is different
              // at new password
              await this.axios.post(
                '/auth/update-user-password',
                {
                  uid: this.newTeacher.uid,
                  password: this.newTeacher.password
                }
              )
            }
            await this.editTeacher(this.newTeacher)
            this.$root.$confirm({ message: 'Se ha editado al información del docente profesores.' })
          } else {
            await this.addTeacher(this.newTeacher)
            this.$root.$confirm({ message: 'Se ha guardado un nuevo registro a la lista de profesores.' })
          }
          this.saving = false
          this.cleanFields()
          this.$emit('close')
        } catch (e) {
          let message = 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.'
          console.log(e.message)
          console.log(e.code)
          if (e.code === 'auth/email-already-in-use') {
            message = 'Este email ya está siendo usado por otra cuenta.'
          } else if (e.code === 'auth/weak-password') {
            message = 'El campo contraseña debe ser un string con al menos seis caracteres.'
          }
          this.$root.$confirm({ message, type: 'alert' })
          this.saving = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
