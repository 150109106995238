<template>
  <base-modal v-if="teacher" v-bind="$attrs" height="590px" width="650px" class="ma-0" intern-card-max-width="570px"
              v-on="$listeners"
  >
    <template v-slot:title>
      <p class="mb-0 mx-auto" style="max-width: 430px">
        Reporte mensual de profesor {{ teacher.firstName }} {{ teacher.lastName }}
      </p>
    </template>
    <template v-slot:body>
      <v-container fluid class="px-0">
        <v-row>
          <v-col cols="6">
            <base-select v-model="selectedYear" :items="years" />
          </v-col>
          <v-col cols="6">
            <base-select v-model="selectedMonth" item-value="value" :items="months" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="overflow-x-hidden overflow-y-auto table-container" style="max-height: 300px;">
            <tslc-table :heads="headers" :items="lessons" show-index>
              <template v-slot:date="{item}">
                <span :class="{'text-decoration-line-through': item.state === 'CANCELLED'}">
                  {{ item.startDate | moment('MMMM Do, YYYY') }}
                </span>
              </template>
              <template v-slot:level="{ item }">
                <span :class="{'text-decoration-line-through': item.state === 'CANCELLED'}">
                  {{
                    `${item.class.level.name} - ${item.class.day} - ${item.class.startHour} a ${item.class.endHour}`
                  }}
                </span>
              </template>
              <template v-slot:actions="{item}">
                <v-btn icon color="#D94343" class="tslc-table__action pa-0" @click="doDeleteReport(item)">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
            </tslc-table>
            <div class="mt-2 text-center">
              <v-progress-circular
                v-if="loading"
                :size="30"
                color="primary"
                indeterminate
              />
              <p v-else-if="lessons.length === 0" class="mb-0 text-center">
                No hay data para mostrar
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="total-hour-text mt-2">
              Total horas: {{ totalHoursMonthly }}h
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </base-modal>
</template>

<script>
import baseModal from '@/components/modals/BaseModal'
import baseSelect from '@/components/inputs/BaseSelect'
import tslcTable from '@/components/TslcTable'
import scheduleService from '@/services/scheduleService'
import momentFunctions from '@/utils/momentFunctions'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TeachingAssistanceModal',
  components: {
    baseModal,
    baseSelect,
    tslcTable
  },
  props: {
    teacher: Object
  },
  data () {
    return {
      loading: false,
      momentFunctions,
      selectedYear: new Date().getFullYear(),
      selectedMonth: new Date().getMonth(),
      years: [],
      months: [
        { text: 'Enero', value: 0 },
        { text: 'Febrero', value: 1 },
        { text: 'Marzo', value: 2 },
        { text: 'Abril', value: 3 },
        { text: 'Mayo', value: 4 },
        { text: 'Junio', value: 5 },
        { text: 'Julio', value: 6 },
        { text: 'Agosto', value: 7 },
        { text: 'Septiembre', value: 8 },
        { text: 'Octubre', value: 9 },
        { text: 'Noviembre', value: 10 },
        { text: 'Diciembre', value: 11 }
      ],
      headers: [{ name: 'Fecha', value: 'date', width: '30%' }, {
        name: 'Nombre del nivel',
        value: 'level',
        width: '60%'
      }, { name: '', value: 'actions', width: '5%' }],
      lessons: [],
      hourTotal: 0
    }
  },
  computed: {
    ...mapState('auth', ['myTeacherUser']),
    totalHoursMonthly () {
      const lessonsDictated = this.lessons.filter(item => item.state !== 'CANCELLED')
      return lessonsDictated.length * 1.5
    }
  },
  watch: {
    async teacher (value) {
      if (value) {
        this.lessons = []
        this.loading = true
        this.lessons = await this.getFilteredLessonsByTeacher(value.id)
        this.loading = false
      }
    },
    async selectedYear () {
      this.lessons = await this.getFilteredLessonsByTeacher(this.teacher.id)
    },
    async selectedMonth () {
      this.lessons = await this.getFilteredLessonsByTeacher(this.teacher.id)
    }
  },
  async mounted () {
    this.loading = true
    this.createYearList()
    this.loading = false
  },
  methods: {
    ...mapActions('schedule', ['deleteLesson']),
    createYearList () {
      const currentYear = new Date().getFullYear()
      for (let i = currentYear; i > currentYear - 3; i--) {
        this.years.unshift(i)
      }
    },
    async getFilteredLessonsByTeacher (teacherId) {
      const teacherLessons = await scheduleService.getLessonsByTeacher(teacherId)

      const firstDay = new Date(this.selectedYear, this.selectedMonth, 1)
      const lastDay = new Date(this.selectedYear, this.selectedMonth + 1, 0)
      const sortTeacherLessons = teacherLessons.filter(lesson => this.$moment(lesson.startDate) >= this.$moment(firstDay) && this.$moment(lesson.startDate) <= this.$moment(lastDay))
      return sortTeacherLessons.sort((a, b) => {
        const aStartDate = this.$moment(a.startDate)
        const bStartDate = this.$moment(b.startDate)
        const result = aStartDate < bStartDate ? -1 : aStartDate > bStartDate ? 1 : 0
        return result * -1
      })
    },
    async doDeleteReport (item) {
      const formattedDate = this.$moment(item.startDate).format('MMMM Do, YYYY')
      const message = `¿Está seguro de que desea eliminar la lección de fecha ${formattedDate}?`
      const confirm = await this.$root.$confirm({
        message: message,
        confirmButtonText: 'Eliminar',
        type: 'alert'
      })
      if (confirm) {
        await scheduleService.deleteLesson(item)
        this.lessons = this.lessons.filter(temporaryItem => temporaryItem !== item)
        this.$root.$confirm({
          message: 'El reporte ha sido eliminado',
          showDismissButton: false
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

.table-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.table-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.table-container::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .9);
}

.total-hour-text {
  color: #404040;
  letter-spacing: -0.04em;
  font-style: normal;
  font-weight: 600;
}

</style>
