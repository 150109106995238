export default {
  async createEvent (payload) {
    try {
      const response = await fetch(
        `${process.env.VUE_APP_CALENDAR_API_BASE_URL}/events`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      return await response.json()
    } catch (e) {
      console.log('service ', e)
    }
  }
}
