import { render, staticRenderFns } from "./NewPasswordTeacherModal.vue?vue&type=template&id=469df58f&scoped=true"
import script from "./NewPasswordTeacherModal.vue?vue&type=script&lang=js"
export * from "./NewPasswordTeacherModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "469df58f",
  null
  
)

export default component.exports