<template>
  <base-modal v-bind="$attrs" width="660px" v-on="$listeners" @click:outside="cleanFields" @close="close">
    <template v-slot:title>
      Cambio de contraseña
    </template>

    <template v-slot:body>
      <v-form ref="form">
        <v-row>
          <v-col class="py-0" cols="12">
            <base-text-field v-model="newTeacher.password" :type="showPassword ? 'text' : 'password'"
                             :rules="[rules.required, rules.min(6)]" label="Ingrese nueva contraseña"
                             :append-icon="showPassword ? 'mdi-eye-off': 'mdi-eye'"
                             @click:append="showPassword = !showPassword"
            />
          </v-col>
          <v-col class="py-0" cols="12">
            <base-text-field v-model="newTeacher.confirmPassword" :type="showConfirmPassword ? 'text' : 'password'"
                             :rules="[rules.required, rules.min(6), rules.isEqual(newTeacher.password)]"
                             label="Confirme nueva contraseña" :append-icon="showConfirmPassword ? 'mdi-eye-off': 'mdi-eye'"
                             @click:append="showConfirmPassword = !showConfirmPassword"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:actions>
      <form-button elevation="0" width="307px" height="50px" class="mt-5 mb-2 mx-auto" :loading="loading"
                   @click="doUpdateTeacherPassword()"
      >
        Guardar cambios
      </form-button>
    </template>
  </base-modal>
</template>

<script>
import baseModal from '@/components/modals/BaseModal'
import baseTextField from '@/components/inputs/BaseTextField'
import formButton from '@/components/buttons/FormButton'
import { isEqual, min, required } from '@/utils/rules'
import { mapState } from 'vuex'

export default {
  name: 'NewPasswordTeacherModal',
  components: {
    baseModal,
    baseTextField,
    formButton
  },
  inheritAttrs: false,
  data () {
    return {
      newTeacher: {
        password: '',
        confirmPassword: ''
      },
      showPassword: false,
      showConfirmPassword: false,
      loading: false,
      rules: {
        required,
        isEqual,
        min
      }
    }
  },
  computed: {
    ...mapState('auth', ['myTeacherUser'])
  },
  methods: {
    async doUpdateTeacherPassword () {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.axios.post(
            '/auth/update-user-password',
            {
              uid: this.myTeacherUser.uid,
              password: this.newTeacher.password
            }
          )
          await this.$store.commit(
            'schedule/REPLACE_TEACHER_BY_ID',
            {
              ...this.myTeacherUser,
              password: this.newTeacher.password,
              showPassword: false
            }
          )
          this.$root.$confirm({ message: 'Su contraseña ha sido cambiada exitosamente' })
          this.loading = false
          this.$emit('close')
        } catch (e) {
          this.loading = false
          this.$root.$confirm({ message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos' })
          console.log(e)
        }
      }
    },
    cleanFields () {
      this.newTeacher = {
        password: '',
        confirmPassword: ''
      }
      this.$refs.form.resetValidation()
    },
    close () {
      this.cleanFields()
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
