<template>
  <base-modal v-bind="$attrs" width="660px" v-on="$listeners" @click:outside="close" @close="close">
    <template v-slot:activator="{ attrs, on }">
      <form-button width="448px" height="50px" class="mt-3 mx-auto" v-bind="attrs" v-on="on">
        Agregar nuevo alumno
      </form-button>
    </template>

    <template v-slot:title>
      {{ isEditing ? `Editar estudiante` : 'Agregar estudiante' }}
    </template>

    <template v-slot:body>
      <v-form ref="form">
        <v-row>
          <v-col class="py-0" cols="12">
            <base-text-field v-model="newStudent.firstName" :rules="[rules.required]" label="Nombre del estudiante" />
          </v-col>
          <v-col class="py-0" cols="12">
            <base-text-field v-model="newStudent.lastName" :rules="[rules.required]" label="Apellidos" />
          </v-col>
          <v-col class="py-0" cols="12">
            <base-text-field v-model="newStudent.email" :rules="[rules.required, rules.email]" label="Correo electrónico" />
          </v-col>
          <v-col class="py-0" cols="12">
            <v-checkbox v-model="newStudent.guest" label="Invitado" color="#243674" hide-details
                        height="50px" plain outlined class="base-modal__input" :disabled="isEditing && newStudent.guest"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:actions>
      <form-button :loading="loading" elevation="0" width="307px" height="50px" class="mt-5 mb-2 mx-auto" @click="saveStudent()">
        Guardar estudiante
      </form-button>
    </template>
  </base-modal>
</template>

<script>
import baseModal from '@/components/modals/BaseModal'
import formButton from '@/components/buttons/FormButton'
import BaseTextField from '@/components/inputs/BaseTextField'
import { mapActions, mapState } from 'vuex'
import { email, required } from '@/utils/rules'
import scheduleService from '@/services/scheduleService'

export default {
  name: 'NewStudentModal',
  components: {
    BaseTextField,
    baseModal,
    formButton
  },
  inheritAttrs: false,
  data () {
    return {
      newStudent: {
        firstName: '',
        lastName: '',
        email: '',
        guest: false,
        attended: false
      },
      rules: {
        required,
        email
      },
      loading: false
    }
  },
  computed: {
    ...mapState('schedule', ['editedStudent']),
    isEditing () {
      return !!this.editedStudent
    }
  },
  watch: {
    editedStudent (newValue) {
      if (this.isEditing) {
        this.newStudent = Object.assign({}, newValue)
      }
    }
  },
  methods: {
    ...mapActions('schedule', ['addStudentToClass']),
    cleanFields () {
      this.newStudent = {
        firstName: '',
        lastName: '',
        email: '',
        guest: false,
        attended: false
      }
    },
    close () {
      this.cleanFields()
      this.$emit('close')
      this.$refs.form.resetValidation()
    },
    async saveStudent () {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          let message = ''
          if (this.isEditing) {
            return
          }
          await this.addStudentToClass(this.newStudent)
          if (this.newStudent.guest) {
            await scheduleService.sendMailWhenCreatingGuestUser(this.newStudent, this.$store.state.schedule.classSelected)
          }
          message = `Se ha agregado correctamente a ${this.newStudent.firstName} ${this.newStudent.lastName} a la lista de alumnos`
          this.loading = false
          this.$root.$confirm({
            message,
            showDismissButton: false
          })
          this.close()
        } catch (e) {
          this.loading = false
          this.close()
          console.log(e)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
