<template>
  <base-modal
    v-bind="$attrs" height="590px" width="650px" class="ma-0" intern-card-max-width="570px"
    v-on="$listeners"
    @click:outside="$emit('close')"
  >
    <template v-slot:title>
      <p class="mb-0 mx-auto" style="max-width: 430px">
        Estudiantes de la clase <br> {{ className }}
      </p>
    </template>
    <template v-slot:body>
      <v-container fluid class="px-0">
        <v-row>
          <v-col cols="12" class="overflow-x-hidden overflow-y-auto table-container" style="max-height: 300px;">
            <tslc-table :heads="headers" :items="students" show-index />
            <div class="mt-2 text-center">
              <p v-if="students.length === 0" class="mb-0 text-center">
                No hay data para mostrar
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="total-hour-text mt-2">
              Total estudiantes: {{ students.length || 0 }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </base-modal>
</template>

<script>
import baseModal from '@/components/modals/BaseModal'
import tslcTable from '@/components/TslcTable'

export default {
  name: 'ClassStudentsModal',
  components: {
    baseModal,
    tslcTable
  },
  props: {
    classItem: Object
  },
  data () {
    return {
      headers: [
        { name: 'Nombres', value: 'name', width: '30%' },
        { name: 'Email', value: 'email', width: '60%' }
      ]
    }
  },
  computed: {
    className () {
      return this.classItem.name
    },
    students () {
      const array = []
      Object.keys(this.classItem.students).forEach((key) => {
        const student = this.classItem.students[key]
        array.push({
          name: `${student.firstName} ${student.lastName}`,
          email: student.email
        })
      })
      return array
    }
  }
}
</script>

<style scoped lang="scss">

.table-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.table-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.table-container::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .9);
}

.total-hour-text {
  color: #404040;
  letter-spacing: -0.04em;
  font-style: normal;
  font-weight: 600;
}

</style>
